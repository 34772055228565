<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                    <ValidationProvider name="Organiation" vid="org_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="organiation_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{$t('org_pro.organization')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="storeItem.org_id"
                          :options="getOrganizationlist"
                          id="organiation_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Category" vid="category_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="category_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{$t('irrigation_config.category')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="storeItem.category_id"
                          :options="categoryList"
                          id="category_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Item code" vid="item_code" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="item_code"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{$t('irrigation_config.item_codel')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="item_code"
                          v-model="storeItem.item_code"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Item Name (En)" vid="item_name" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="item_name"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('irrigation_config.item_name')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="item_name"
                          v-model="storeItem.item_name"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Item Name (Bn)" vid="item_name_bn" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="item_name_bn"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('irrigation_config.item_name_bn')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="item_name_bn"
                          v-model="storeItem.item_name_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Measurement Unit" vid="measurement_unit_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="measurement_unit_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{$t('irrigation_config.unit_namel')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="storeItem.measurement_unit_id"
                          :options="measurementUnitList"
                          id="measurement_unit_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Specification (En)" vid="specification">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="specification"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                          {{$t('irrigation_config.specification')}}
                        </template>
                        <b-form-input
                          id="specification"
                          v-model="storeItem.specification"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Specification (Bn)" vid="specification_bn">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="specification_bn"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                          {{$t('irrigation_config.specification_bn')}}
                        </template>
                        <b-form-input
                          id="specification_bn"
                          v-model="storeItem.specification_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { storeItemStore, storeItemUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getstoreItemData()
      this.storeItem = tmp
    }
    // this.getCategorylist()
    // this.getMeasurementUnitList()
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      storeItem: {
        org_id: 0,
        category_id: 0,
        item_code: '',
        item_name: '',
        item_name_bn: '',
        measurement_unit_id: 0,
        specification: '',
        specification_bn: ''
      }
    }
  },
  computed: {
    getOrganizationlist: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    categoryList: function () {
      return this.$store.state.IrriConfig.commonObj.categoryList.filter(item => item.status === 0 && item.org_id === this.storeItem.org_id)
    },
    measurementUnitList: function () {
      return this.$store.state.IrriConfig.commonObj.unitList.filter(item => item.status === 0)
    }
  },
  methods: {
    getstoreItemData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      if (this.storeItem.id) {
        result = await RestApi.putData(irriSchemeServiceBaseUrl, `${storeItemUpdate}/${this.id}`, this.storeItem)
      } else {
        result = await RestApi.postData(irriSchemeServiceBaseUrl, storeItemStore, this.storeItem)
      }
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      if (result.success) {
        this.$store.dispatch('IrriConfig/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
